import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import { textStyle, backgroundStyle } from '../../utils'

export const VideoModule = ({ videoUrl, heading, style }) => (
  <Container
    fluid
    className="video-module"
    css={backgroundStyle(style?.backgroundColor)}
  >
    <Row className="module-heading mw mx-auto text-center py-3">
      <h2 css={textStyle(style?.headingColor)}>{heading && heading}</h2>
    </Row>
    <Row
      className="py-0 px-4 mx-auto my-4"
      style={{ height: '660px', maxWidth: '1200px' }}
    >
      <Col>
        <ReactPlayer
          width="100%"
          height="100%"
          url={videoUrl}
          controls
          config={{
            youtube: {
              playerVars: { showinfo: 1 },
            },
          }}
        />
      </Col>
    </Row>
  </Container>
)

export const mapVideoModuleProps = props => props
